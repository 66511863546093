import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import "../software/Software.css";
import "./SoftwareDetailed.css";
import "../product-detail/ProductDetailed.css";
import Parser from "html-react-parser";

import ChatButton from "../../components/chatbutton/ChatButton";
import DownloadCaepipe from "../downloadcaepipe/DownloadCaepipe";
import downloadCaepipe from "../../../assets/download-caepipe.png";
import { Report } from "../report/Report";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import ButtonLoader from '../../components/loader/Loader'

export class SoftwareDetailed extends Component {
  constructor(props) {
    super(props);

    //dynamic validation for title field and content field
    this.validator = new SimpleReactValidator({
      messages: {
        required: "The :attribute required.",
        alpha: "The :attribute must be string.",
      },
    });

    this.state = {
      contactSupplierModel: false,
      reportModal: false,
      details: [],
      ispageShow: false,
      relatedDetails: [],
      enquiry: "",
      serialId: "",
      reportModule: "",

      senderName: "",
      senderEmail: "",
      serial: "",

      isDownload: false,
      softwareEXElink: '',
      softwareZIPlink: '',
      softwareLIClink: '',
      isButtonLoding: false,
      subscriber:false,
      nonSubscriber:false,
      invalidSerial:false,
    };
  }

  // set ternery condition on the create post modal for showing the modal
  showModal = async () => {

    const userData = localStorage.getItem("userData");
    const userDetails = JSON.parse(userData);

    await this.setState({
      senderName: userDetails.userName,
      senderEmail: userDetails.userEmail,
      serial: "",
    })

    const contactSupplierModel =
      this.state.contactSupplierModel === false ? true : false;
    await this.setState({
      contactSupplierModel: contactSupplierModel,
    });
  };

  hideContactModal = async () => {

    this.validator.hideMessages();
    this.forceUpdate();

    await this.setState({
      contactSupplierModel: false,
      isDownload: false,
      softwareEXElink: '',
      softwareZIPlink: '',
      softwareLIClink: '',
    });
  };

  reportModal = async (software) => {

    const reportModal = this.state.reportModal === false ? true : false;
    await this.setState({
      reportModal: reportModal,
      reportModule: software,
    });
  };

  hideReportModal = async () => {
    await this.setState({
      reportModal: false,
    });
  };

  softwareDetails = async () => {
    const id = this.props.match.params.id;

    try {
      const products = await axios.get(
        `${process.env.REACT_APP_API_URL}software/getsoftware/${id}`,

        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (products.status === 200 && products.data.status === true) {
        this.setState({
          details: products.data.datas,
          ispageShow: true,
        });

      } else {
        this.props.history.goBack();
      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    // related products
    const relatedData = {
      sfname: this.state.details.name,
    };

    try {
      const relatedProducts = await axios.post(
        `${process.env.REACT_APP_API_URL}software/getrelatedsoftware`,
        relatedData,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (
        relatedProducts.status === 200 &&
        relatedProducts.data.status === true
      ) {
        this.setState({
          relatedDetails: relatedProducts.data.datas,
          serialId: id,
        });

      }
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  submitContactSupplier = async () => {
    if (this.validator.allValid()) {

      this.setState({
        isButtonLoding: true
      })
      const supplierData = {
        uname: this.state.senderName,
        uemail: this.state.senderEmail,
        serial: this.state.serial,
      };

      try {
        const formres = await axios.post(
          `${process.env.REACT_APP_API_URL}software/serial`,
          supplierData,
          {
            headers: {
              Auth: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (formres.data.status === true) {

          await this.downloadSoftware(this.state.details, this.state.serial);

          if(formres.data.message === 'ME&S Subscriber') {
            this.setState({
              subscriber:true,
              nonSubscriber:false,
              invalidSerial:false,
            })
          } else if (formres.data.message === 'Not ME&S Subscriber') {
            this.setState({
              subscriber:false,
              nonSubscriber:true,
              invalidSerial:false,
            })
          } else {
            this.setState({
              subscriber:false,
              nonSubscriber:false,
              invalidSerial:true,
            })
          }

          this.setState({
            isDownload: true,
            softwareEXElink: formres.data.datas.sstSoftwareExe,
            softwareZIPlink: formres.data.datas.sstSoftwareZip,
            softwareLIClink: formres.data.datas.licenseManager,
          })
        } else {
          this.setState({
            subscriber:false,
            nonSubscriber:false,
            invalidSerial:true,
          })
          // toast.error(formres.data.errors != "" ? formres.data.errors : formres.data.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location = process.env.PUBLIC_URL;
        } else if (err?.response?.data?.error != '') {
          this.setState({
            subscriber:false,
            nonSubscriber:false,
            invalidSerial:true,
          })
          // toast.error(err?.response?.data?.error, {
          //   position: "top-right",
          //   autoClose: 4000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        } else {
          this.setState({
            subscriber:false,
            nonSubscriber:false,
            invalidSerial:true,
          })
          // toast.error(err.message, {
          //   position: "top-right",
          //   autoClose: 4000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      }

      this.setState({
        isButtonLoding: false
      })
      // this.hideContactModal();
      this.validator.hideMessages();
      this.forceUpdate();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value?.replace(/^\s+/g, ''),
    });
  };

  componentDidMount = async () => {
    await this.softwareDetails();
    
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.softwareDetails();
    }
  };

  downloadSoftware = async (userDetails, serial) => {
    
    var data = {
      userDetails:userDetails,
      serial:serial,
    }

    try {
      const software = await axios.post(
        `${process.env.REACT_APP_API_URL}downloadsoftware`,
        data,
        {
          headers: {
            Auth: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (software.status === 200 && software.data.status === true) {
        
      } else {
        
      }
      
    } catch (err) {
      if (err?.response?.status === 403) {
        localStorage.removeItem("token");
        window.location = process.env.PUBLIC_URL;
      } else if (err?.response?.data?.error != '') {
        toast.error(err?.response?.data?.error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  render() {

    const softUid = Object.keys(this.state.details).length
      ? this.state.details.uid
      : "";
//Added by PBK to check whether software is uploaded directly or the download URL is entered
      const furl = this.state.details.url === "" ? this.state.details.software : this.state.details.url;

    return (
      
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        {this.state.contactSupplierModel ? (
         
          // {/* Contact Supplier Model START */}

          <div className="creategroup-modal-container open">
            <div className="creategroup-modal send-message-modal">
              <button
                type="button"
                className="close-button-modal"
                id="close-createGroup"
                onClick={this.hideContactModal}
              >
                <i className="fas fa-times" />
              </button>

              <div className="creategroup-header">
                <h1 className="header">
                  Download your copy of the program
                </h1>
              </div>

              <div className="creategroup-content login-content-fields">
                <form action="" className="login">
                  <input
                    type="text"
                    className="groupname"
                    placeholder="Name"
                    name="senderName"
                    value={this.state.senderName}
                    onChange={this.handleInputChange}
                  />

                  {this.validator.message(
                    "Name field is",
                    this.state.senderName,
                    "required|string"
                  )}

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Email"
                    name="senderEmail"
                    value={this.state.senderEmail}
                    onChange={this.handleInputChange}
                  />

                  {this.validator.message(
                    "Email field is",
                    this.state.senderEmail,
                    "required|email"
                  )}

                  <input
                    type="text"
                    className="groupname"
                    placeholder="Serial"
                    name="serial"
                    value={this.state.serial}
                    onChange={this.handleInputChange}
                  />

                  {this.validator.message(
                    "Serial key is",
                    this.state.serial,
                    "required|string"
                  )}

                    <button
                        type="button"
                        className="login-button creategroup-button caepipe-program softDownBtn"
                        id="mycaepipe"
                        onClick={this.submitContactSupplier}
                        disabled={this.state.isButtonLoding}
                      >
                        <h2>{this.state.isButtonLoding ? <ButtonLoader color="#8a8989" /> : 'Download'}</h2>
                      </button>
                  {
                    (this.state.isDownload === false) ? (
                      ''
                    ) : (
                      <>
                      {this.state.subscriber ? "" :
                      
                      this.state.nonSubscriber ? <p className="softErrorMsg">You do not appear to be a current ME&S Subscriber. Below are the files based on the most recent version that your serial number is eligible for according to our records.</p> :

                      <p className="softErrorMsg">You do not appear to be a current Subscriber. If this is incorrect, please contact <a href="mailto:support@sstusa.com">support@sstusa.com</a> with your product name and serial number.</p>
                    
                    }
                      
                     {!this.state.invalidSerial && 
                      <div className="software_desc">
                      <p>1. SST License Manager is required only for Soft Licensing. Download <a href={this.state.softwareLIClink} target="_blank"> SST License Manager </a> only if needed.</p>
                      <p>2. ZIP version  <a href={this.state.softwareZIPlink} target="_blank"> - Download this file (.zip)  </a> ({this.state.softwareZIPlink?.split('/').pop()}) Extract the contents of .zip file to your computer and run .exe file to install the software.</p>
                    </div>
                     } 
                      
                      </>
                    )
                  }

                </form>
              </div>
            </div>
          </div>
          // {/* Contact Supplier Model END */}
        ) : (
          ""
        )}

        {this.state.reportModal ? (
          <Report
            hideReportModal={this.hideReportModal}
            softwareId={this.state.serialId}
            softwareUid={softUid}
            module={this.state.reportModule}
          />
        ) : (
          ""
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast-container"
          toastClassName="dark-toast"
        />

        {this.state.ispageShow ? (
          <section className="detailed-software">
            <div className="detailed-container">
              <div className="report-button" onClick={() => this.reportModal("software")}>
                <button type="button" className="caepipe-download">
                  <i className="fas fa-chevron-down" />
                </button>
                <div className="report-container">
                  <button
                    type="button"
                    className="report-block"
                  >
                    <h2 className="header">Report Abuse</h2>
                  </button>
                </div>
              </div>

              <div className="download-button">      

                {this.state.details.dwnld === "dd" ? (

                  <a href={furl} download target="_blank">
                    <img src={downloadCaepipe} alt="DownloadCaepipe" onClick={() => this.downloadSoftware(this.state.details, this.state.serial)}/>
                  </a>
                )
                  :
                  (
                    <button
                      type="button"
                      className="caepipe-download"
                      id="caepipe-modal"
                      onClick={() => this.showModal()}
                    >
                      <img src={downloadCaepipe} alt="DownloadCaepipe" />
                    </button>
                  )}

              </div>

              <div className="detail-header">
                <h1 className="header">{this.state.details.name}</h1>
              </div>

              <div className="content-container content-container-part" id="caepipecontainer">
                <div className="caepipe-image" id="Softcaepipeimage">
                  <img src={this.state.details.logo} alt="" />
                </div>

                <div className="caepipe-info">
                  <div className="caepipe-location">
                    <div className="location-icon">
                      <div className="icon-content">
                        <h1 className="header">{this.state.details.name}</h1>
                      </div>
                    </div>
                  </div>

                  <div className="evalution-content">
                    <div className="evalution-para">
                      <h2 className="grey-header">
                        {Parser(this.state.details.description)}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="related-software-container">
                <div className="container-header">
                  <h1 className="header">Related Software</h1>
                </div>

                <div className="related-container">
                  <div className="related-content software-related-content">
                    {this.state.relatedDetails.map((detail, key) => (
                      <Link to={`/software-detail/${detail.id}`} key={key}>
                        <div className="product-boxes-content software-boxes-content">
                          <div className="product-image related-image related-jobs-image">
                            <img src={detail.logo} alt="" />
                          </div>
                          <div className="product-info software-info">
                            <div className="product-name">
                              <h2 className="header">{detail.name}</h2>
                            </div>
                            <div className="product-place software-content related-jobs-content">
                              <h2 className="header grey-header truncate">
                                <ShowMoreText
                                  lines={2}
                                  more="Read more"
                                  less="Read less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  onClick={this.executeOnClick}
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                >
                                  <span dangerouslySetInnerHTML={{ __html: detail.description }} />
                                </ShowMoreText>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        <ChatButton />
      </>
    );
  }
}

export default withRouter(SoftwareDetailed);
